"use strict";

import "regulus-oscar";

$(function(){
    var form = $('#product-enquiry form');
    if(!form.length){
        return;
    }

    form = form[0];
    form.addEventListener('submit', function(e){
        e.preventDefault();

        var formSubmitted = false;
        var submitForm = function() {
            if (!formSubmitted) {
                formSubmitted = true;
                form.submit();
            }
        };

        // In case gtag fails
        setTimeout(submitForm, 1000);

        if(window.gtag){
            gtag('event', 'product_enquiry', {'event_callback': submitForm});
        }
    });
});


$(function(){
    var form = $('#mc-embedded-subscribe-form');
    if(!form.length){
        return;
    }

    form = form[0];
    form.addEventListener('submit', function(e){
        e.preventDefault();

        var formSubmitted = false;
        var submitForm = function() {
            if (!formSubmitted) {
                formSubmitted = true;
                form.submit();
            }
        };

        // In case gtag fails
        setTimeout(submitForm, 1000);

        if(window.gtag){
            gtag('event', 'newsletter_signup', {'event_callback': submitForm});
        }
    });
});
